<template>
  <div :id="idFake">
    <label for="">{{ label }}</label>
    <div class="image-input1 mt-2">
      <b-media
        class="mb-2  "
      >
        <template #aside>
          <div
            id="appImg"
          >
            <div
              v-if="avatar&&avatar!==null&&avatar!==''"
              class="image-input image-input-active d-flex"
            >
              <div
                class="image-preview"
                :style="{width:width,height:height}"
              >
                <img
                  class="img-responsive h-100"
                  :src="($serverfile+avatar)"
                >
                <b-dropdown
                  class="remove-file"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="CameraIcon"
                      class="icon-camera"
                    />
                  </template>
                  <b-dropdown-item
                    @click="openinput"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      class="mr-50"
                    />
                    <span>Tải ảnh lên</span>
                  </b-dropdown-item>
                  <input
                    :id="idInput"
                    type="file"
                    class="d-none"
                    @change="addImage"
                  >
                  <b-dropdown-item
                    @click="removeFile"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>Xóa ảnh</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div
              v-else
              class="image-input image-input-tbd d-flex"
            >
              <div
                :id="id"
                :style="{width:width,height:height}"
                class="image-preview dropzone d-flex justify-content-center align-items-center"
                @click="openinput"
              >
                <feather-icon
                  icon="CameraIcon"
                  class="icon-camera"
                />
              </div>
              <input
                :id="idInput"
                type="file"
                class="d-none"
                @change="addImage"
              >
            </div>
          </div>
        </template>
      </b-media>
    </div>
    <b-tooltip
      :target="id"
      title="Định dạng: JPG, PNG, BMP, GIF. Kích thước tối ưu: 168 x 94 Dung lượng: 5Mb"
      placement="right"
    ></b-tooltip>
  </div>

</template>

<script>
import {
  BRow, BCol, BFormCheckbox, BFormTextarea, BDropdown, BMedia,
  BAvatar,
  BDropdownItem,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    BTooltip,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      default: 'avatar',
    },
    width: {
      type: String,
      default: '96px',
    },
    height: {
      type: String,
      default: '96px',
    },
    id: {
      type: String,
      default: 'id',
    },
    idInput: {
      type: String,
      default: 'idInput',
    },
    idFake: {
      type: String,
      default: 'idFake',
    },
    urlImg: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      avatar: '',

    }
  },
  watch: {
    urlImg(val) {
      if (val && this.id === 'BT') {
        this.avatar = this.urlImg.logo
      } else if (val && this.id === 'BTT') {
        this.avatar = this.urlImg.favicon
      } else if (val && this.id === 'BIA') {
        this.avatar = this.urlImg.banner
      } else if (val && this.id === 'Acount') {
        this.avatar = this.urlImg.avatar
      }
    },
  },
  methods: {
    // Mở input file
    openinput() {
      document.getElementById(this.idInput).click()
    },

    // Thêm ảnh
    async addImage(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      const model = {
        files: file,
        isSecure: false,
      }
      const response = await this.uploadFile(model)
      this.avatar = response
      this.$emit('url', response)
    },

    // Up ảnh
    async uploadFile(model) {
      const formData = new FormData()
      formData.append('IsSecure', model.isSecure)
      formData.append('formFile', model.files)
      if (model.IsBackground) { formData.append('IsBackground ', model.IsBackground) }
      if (model.fileType) formData.append('FileType', model.fileType)
      try {
        this.$showAllPageLoading()
        const { data } = await axiosApiInstance.post(ConstantsApi.UPLOAD_IMG_SYSTEM, formData)
        this.$hideAllPageLoading()
        return data
      } catch (err) {
        return err.response.data
      }
    },
    // Xoá ảnh
    removeFile() {
      this.avatar = null
      if (this.id === 'BT') {
        this.urlImg.logo = null
      } else if (this.id === 'BTT') {
        this.urlImg.favicon = null
      } else if (this.id === 'BIA') {
        this.urlImg.banner = null
      }
    },
  },
}
</script>

<style lang="scss">
.image-preview {
    cursor: pointer;
    overflow: hidden;
    border-radius: 6px;
    img {
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }
  .icon-camera {
    color: white;
    height: 50px;
    width: 50px;
  }

  .dropzone {
    background: #efefef;
  }

  .remove-file {
    position: absolute;
    margin-top: 28px;
    margin-left: -66px;
    width: 40px;
    height: 40px;
  }
.image-input1{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
#idBTT{
    .remove-file {
    position: absolute;
    margin-top: 12px;
    margin-left: -95px;
    width: 40px;
    height: 40px;
  }
}
</style>
